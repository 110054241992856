<!-- eslint-disable vue/no-mutating-props -->
<template>
  <!-- <card>
    <h5 slot="header" class="title">Trocar a Senha</h5>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <div class="form-group" :class="{'has-danger': password && !validateOldPassword()}">
          <base-input
            type="password"
            label="Senha Antiga"
            v-model.trim="password"
            placeholder="******"
            @change="passwordChanged=true"
            >
          </base-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <div class="form-group" :class="{'has-danger': newPass && !validateNewPassword()}">
          <base-input
            type="password"
            label="Nova Senha"
            v-model.trim="newPass"
            placeholder="******"
            @change="newPassChanged=true"
          >
          </base-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <div class="form-group" :class="{'has-danger': newPassConfirmChanged && !validateCheckPassword()}">
          <base-input
            type="password"
            label="Confirmação da Nova Senha"
            v-model.trim="newPassConfirm"
            placeholder="******"
            @change="newPassConfirmChanged=true"
          >
          </base-input>
        </div>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="changePassword()">Salvar</base-button>
  </card> -->
  <amplify-forgot-password
    header-text="Confirmação de Mudança de Senha"
    username-alias="email"
    slot="forgot-password"
  ></amplify-forgot-password>
</template>
<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
export default {
  data() {
      return {
        passwordChanged: false,
        newPassChanged: false,
        newPassConfirmChanged: false,
        password: '',
        newPass: '',
        newPassConfirm: '',
        diffPass: false,
    }
  },
  methods: {
    getUsuario() {
      try {
        let user = JSON.parse(localStorage.ADMIN_AUTH_USER)
        return user
      } catch (error) {
        console.error(error)
        localStorage.ADMIN_AUTH_USER = null
      }
    }
  },
  mounted() {
    this.$watch('$route', this.disableRTL, { immediate: true });
    this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
  },
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
        if (this.user) {
        let userGuru = {
          'id': this.user['username'],
          'email': this.user.attributes['email'],
          'nome': this.user.attributes['name'],
          'token': this.user.signInUserSession['idToken']['jwtToken']
        };
        localStorage.ADMIN_AUTH_USER = JSON.stringify(userGuru);
      }
    });
    $(document).ready( function () {
      // console.log('anchor:')
      // console.log($('div').innerHTML)
      //.css({ display: "block" });
    })
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
    };
  },
  beforeDestroy() {
    localStorage.ADMIN_AUTH_USER = null
    this.unsubscribeAuth();
  }    
};
</script>
<style lang="scss">
  button.anchor {
    display: none !important;
    text-decoration: underline;
  }
</style>
